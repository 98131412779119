// src/components/PulseStrip/index.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import './styles.css';

const PulseStrip = () => {
  const { directions, fareInfo } = useSelector(state => ({
    directions: state.map.directions,
    fareInfo: state.user.fareInfo
  }));

  if (!directions || !fareInfo) return null;

  return (
    <AnimatePresence>
      <motion.div
        className="pulse-strip"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
      >
        <div className="pulse-dot" />
        <div className="pulse-info">
          <div className="info-item">
            <span className="label">Distance</span>
            <span className="value">{fareInfo.distanceKm} km</span>
          </div>
          <div className="separator" />
          <div className="info-item">
            <span className="label">Duration</span>
            <span className="value">{fareInfo.estTime}</span>
          </div>
          <div className="separator" />
          <div className="info-item highlight">
            <span className="label">Fare</span>
            <span className="value">HK${fareInfo.ourFare.toFixed(2)}</span>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default React.memo(PulseStrip);