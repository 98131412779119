import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { toggleDepartTimeModal } from '../../features/user/userSlice';
import { USER_STATES } from '../../constants/states';
import PulseStrip from '../PulseStrip'; // Import the actual PulseStrip component
import './styles.css';

const CarOption = ({ car, isSelected, onSelect }) => (
  <div 
    className={`car-option ${isSelected ? 'selected' : ''}`}
    onClick={onSelect}
  >
    <div className="car-info">
      <div className="car-content">
        <div className="car-details">
          <span className="car-name">{car.name}</span>
          <span className="car-eta">{car.eta}</span>
        </div>
        <span className="car-price">{car.price}</span>
      </div>
      <img src={car.image} alt="Car" className="car-image" />
    </div>
  </div>
);

const ChooseCarButton = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user.userState);
  const [selectedCar, setSelectedCar] = useState(null);
  const [showPulse, setShowPulse] = useState(false);

  const carOptions = [
    {
      id: 'mtc-4',
      name: 'MTC · 4',
      price: 'HK$218.09',
      eta: '2:54 PM · 1 min away',
      image: '/car.png'
    },
    {
      id: 'mtc-7',
      name: 'MTC · 7',
      price: 'HK$258.09',
      eta: '2:56 PM · 2 mins away',
      image: '/car7.png'
    }
  ];

  useEffect(() => {
    if (selectedCar) {
      setShowPulse(true);
      const timer = setTimeout(() => {
        setShowPulse(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [selectedCar]);

  if (userState !== USER_STATES.SELECTED_DEPARTURE) {
    return null;
  }

  const handleCarSelection = (car) => {
    setSelectedCar(car);
    setShowPulse(true); // Trigger pulse on selection
  };

  const handleConfirmSelection = () => {
    if (selectedCar) {
      dispatch(toggleDepartTimeModal());
    }
  };

  // Mock data for PulseStrip when a car is selected
  const mockPulseData = selectedCar ? {
    directions: { routes: [{ legs: [{ duration: { text: selectedCar.eta } }] }] },
    fareInfo: {
      distanceKm: '0.5',
      estTime: '1 min',
      ourFare: parseFloat(selectedCar.price.replace('HK$', ''))
    }
  } : null;

  return (
    <div className="car-selection-sheet">
      <div className="sheet-content">
        {/* Title */}
        <div className="sheet-title">
          <h2>Choose a car</h2>
        </div>

        {/* Custom PulseStrip styling for the sheet */}
        <div className="pulse-strip-container">
          <AnimatePresence>
            {showPulse && mockPulseData && (
              <motion.div
                className="car-selection-pulse-strip"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.2 }}
              >
                <PulseStrip {...mockPulseData} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Car Options */}
        <div className="car-options">
          {carOptions.map(car => (
            <CarOption
              key={car.id}
              car={car}
              isSelected={selectedCar?.id === car.id}
              onSelect={() => handleCarSelection(car)}
            />
          ))}
        </div>

        {/* Action Button */}
        <div className="action-button-container">
          <button
            className={`action-button ${selectedCar ? 'selected' : ''}`}
            onClick={handleConfirmSelection}
          >
            {selectedCar ? `Choose ${selectedCar.name}` : 'Choose a car'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ChooseCarButton);
