// src/utils/fareCalculator.js
import { FARE_CONFIG, PEAK_HOURS } from '../constants/config';

export const calculateFare = (distance, duration, currentTime = new Date()) => {
  // Base calculations
  const distanceKm = (distance / 1000).toFixed(2);
  const extraMeters = Math.max(0, distance - 2000); // First 2km base fare
  const increments = Math.floor(extraMeters / FARE_CONFIG.DISTANCE_INCREMENT);
  
  // Calculate taxi fare
  const taxiFareEstimate = FARE_CONFIG.BASE_TAXI_FARE + (increments * 1.7); // HK$1.7 per 200m

  // Check if peak hour
  const hour = currentTime.getHours();
  const isPeakHour = PEAK_HOURS.some(period => 
    hour >= period.start && hour < period.end
  );

  // Set minimum fare based on time
  const startingFare = isPeakHour 
    ? FARE_CONFIG.PEAK_HOUR_MIN_FARE 
    : FARE_CONFIG.OFF_PEAK_MIN_FARE;

  // Calculate our fare with discount
  const ourFare = Math.max(
    Math.round(taxiFareEstimate * FARE_CONFIG.OUR_FARE_MULTIPLIER),
    startingFare
  );

  // Format duration
  const hrs = Math.floor(duration / 3600);
  const mins = Math.floor((duration % 3600) / 60);
  const estTime = `${hrs > 0 ? `${hrs}h ` : ''}${mins}m`;

  return {
    ourFare,
    taxiFareEstimate,
    distanceKm: parseFloat(distanceKm),
    estTime,
    isPeakHour,
    savings: taxiFareEstimate - ourFare,
    breakdown: {
      baseFare: FARE_CONFIG.BASE_TAXI_FARE,
      distanceCharge: increments * 1.7,
      discount: (taxiFareEstimate - ourFare).toFixed(2),
      minimumFare: startingFare
    }
  };
};

export const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-HK', {
    style: 'currency',
    currency: 'HKD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

export const getNextFareEstimate = (distance, currentTime = new Date()) => {
  // Look ahead 1 hour
  const nextHour = new Date(currentTime);
  nextHour.setHours(currentTime.getHours() + 1);
  
  return calculateFare(distance, 0, nextHour);
};
