// src/features/map/mapSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { VIEWS, ZOOM_THRESHOLDS, MAP_ANIMATION } from '../../constants/states';
import { getViewSettingsForState } from '../../utils/viewUtils';

export const handleMapStateChange = async (mapInstance, newState) => {
  if (!mapInstance || !newState) return null;

  return new Promise((resolve, reject) => {
    try {
      console.log('Starting map state change:', newState);
      
      requestAnimationFrame(async () => {
        try {
          // Step 1: Clear existing zoom constraints
          console.log('Clearing zoom constraints');
          mapInstance.setOptions({
            minZoom: undefined,
            maxZoom: undefined
          });

          await new Promise(resolve => setTimeout(resolve, 50));

          // Step 2: Handle center changes
          if (newState.center) {
            console.log('Setting new center:', newState.center);
            await new Promise(resolve => {
              const listener = mapInstance.addListener('idle', () => {
                google.maps.event.removeListener(listener);
                resolve();
              });
              mapInstance.panTo(newState.center);
            });
          }

          // Step 3: Set zoom level
          if (newState.zoom !== undefined) {
            console.log('Setting new zoom:', newState.zoom);
            await new Promise(resolve => {
              const listener = mapInstance.addListener('idle', () => {
                google.maps.event.removeListener(listener);
                resolve();
              });
              mapInstance.setZoom(newState.zoom);
            });
          }

          // Step 4: Set tilt and heading
          if (newState.tilt !== undefined || newState.heading !== undefined) {
            console.log('Setting tilt/heading:', { tilt: newState.tilt, heading: newState.heading });
            if (newState.tilt !== undefined) mapInstance.setTilt(newState.tilt);
            if (newState.heading !== undefined) mapInstance.setHeading(newState.heading);
            await new Promise(resolve => setTimeout(resolve, 100));
          }

          // Step 5: Set zoom constraints
          await new Promise(resolve => setTimeout(resolve, 100));
          if (newState.minZoom !== undefined && newState.maxZoom !== undefined) {
            console.log('Setting final zoom constraints:', {
              min: newState.minZoom,
              max: newState.maxZoom
            });
            mapInstance.setOptions({
              minZoom: newState.minZoom,
              maxZoom: newState.maxZoom
            });
          }

          console.log('Map state change complete');
          resolve();
        } catch (error) {
          console.error('Map state change error:', error);
          reject(error);
        }
      });
    } catch (error) {
      console.error('Map state change outer error:', error);
      reject(error);
    }
  });
};

const initialState = {
  mapInstance: null,
  center: VIEWS.CITY_VIEW.center,
  zoom: ZOOM_THRESHOLDS.CITY_VIEW.default,
  tilt: VIEWS.CITY_VIEW.tilt,
  heading: VIEWS.CITY_VIEW.heading,
  directions: null,
  selectedDistrict: null,
  previousView: null,
  currentView: VIEWS.CITY_VIEW,
  isLoaded: false,
  error: null,
  navigationHistory: [VIEWS.CITY_VIEW],
  isTransitioning: false,
  transitionError: null,
  lastTransitionTime: null,
  pendingTransition: null
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapInstance: (state, action) => {
      state.mapInstance = action.payload;
      state.isLoaded = true;
      state.error = null;
    },

    updateMapView: (state, action) => {
      const { center, zoom, tilt, heading } = action.payload;
      if (center) state.center = center;
      if (zoom !== undefined) {
        const thresholds = ZOOM_THRESHOLDS[state.currentView.name] || ZOOM_THRESHOLDS.CITY_VIEW;
        state.zoom = Math.min(Math.max(zoom, thresholds.min), thresholds.max);
      }
      if (tilt !== undefined) state.tilt = tilt;
      if (heading !== undefined) state.heading = heading;
    },

    setView: (state, action) => {
      if (state.isTransitioning) {
        state.pendingTransition = action.payload;
        return;
      }

      const newView = action.payload;
      if (!newView?.name) {
        console.warn('Invalid view provided to setView');
        return;
      }

      // Get complete view settings with proper thresholds
      const thresholds = ZOOM_THRESHOLDS[newView.name] || ZOOM_THRESHOLDS.CITY_VIEW;
      const viewSettings = getViewSettingsForState(newView.name, newView.center);

      const safeZoom = Math.min(Math.max(
        newView.zoom || viewSettings.zoom || thresholds.default,
        thresholds.min,
        thresholds.max
      ));

      // Store previous view
      state.previousView = { ...state.currentView };

      // Set new view state
      state.currentView = {
        ...viewSettings,
        ...newView,
        zoom: safeZoom,
        minZoom: thresholds.min,
        maxZoom: thresholds.max
      };

      state.navigationHistory.push(state.currentView);
      state.isTransitioning = true;
      state.lastTransitionTime = Date.now();
      state.transitionError = null;
      state.pendingTransition = null;
    },

    goBack: (state, action) => {
      if (state.navigationHistory.length <= 1 || state.isTransitioning) return;

      const currentView = state.currentView;
      const district = action.payload?.departureDistrict || state.selectedDistrict;
      state.navigationHistory.pop();

      let nextView;
      if (currentView.name === VIEWS.STATION_VIEW.name && district) {
        nextView = getViewSettingsForState('AREA_VIEW', district.position);
      } else if (currentView.name === VIEWS.AREA_VIEW.name && district) {
        nextView = getViewSettingsForState('DISTRICT_VIEW', district.position);
      } else if (currentView.name === VIEWS.ME_VIEW.name) {
        nextView = getViewSettingsForState('CITY_VIEW');
      } else {
        nextView = state.navigationHistory[state.navigationHistory.length - 1];
      }

      state.currentView = nextView;

      if (nextView.name === VIEWS.CITY_VIEW.name) {
        state.selectedDistrict = null;
        state.directions = null;
      }
    },

    setTransitionState: (state, action) => {
      state.isTransitioning = action.payload;
      if (!action.payload) {
        state.transitionError = null;
        state.lastTransitionTime = null;
        if (state.pendingTransition) {
          const viewSettings = getViewSettingsForState(
            state.pendingTransition.name,
            state.pendingTransition.center
          );
          state.currentView = {
            ...viewSettings,
            ...state.pendingTransition
          };
          state.pendingTransition = null;
        }
      }
    },

    setSelectedDistrict: (state, action) => {
      state.selectedDistrict = action.payload;
    },

    clearSelectedDistrict: (state) => {
      state.selectedDistrict = null;
    },

    setDirections: (state, action) => {
      state.directions = action.payload;
    },

    clearDirections: (state) => {
      state.directions = null;
    },

    setMapError: (state, action) => {
      state.error = action.payload;
    },

    setTransitionError: (state, action) => {
      state.transitionError = action.payload;
      state.isTransitioning = false;
      state.pendingTransition = null;
    },

    resetMapState: (state) => ({
      ...initialState,
      mapInstance: state.mapInstance,
      isLoaded: state.isLoaded
    })
  }
});

export const {
  setMapInstance,
  updateMapView,
  setView,
  goBack,
  setTransitionState,
  setSelectedDistrict,
  clearSelectedDistrict,
  setDirections,
  clearDirections,
  setMapError,
  setTransitionError,
  resetMapState,
} = mapSlice.actions;

export const selectMapState = state => ({
  mapInstance: state.map.mapInstance,
  center: state.map.center,
  zoom: state.map.zoom,
  tilt: state.map.tilt,
  heading: state.map.heading,
  currentView: state.map.currentView,
  previousView: state.map.previousView,
  directions: state.map.directions,
  error: state.map.error,
  isLoaded: state.map.isLoaded,
  selectedDistrict: state.map.selectedDistrict,
  isTransitioning: state.map.isTransitioning,
  transitionError: state.map.transitionError,
  lastTransitionTime: state.map.lastTransitionTime,
  pendingTransition: state.map.pendingTransition,
  navigationHistory: state.map.navigationHistory,
  canGoBack: state.map.navigationHistory.length > 1
});

export default mapSlice.reducer;
