import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDepartTimeModal } from '../../features/user/userSlice';
import { USER_STATES } from '../../constants/states';
import './styles.css';

const ChooseCarButton = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user.userState);

  if (userState !== USER_STATES.SELECTED_DEPARTURE) {
    return null;
  }

  return (
    <div className="choose-car-container">
      <button
        className="choose-car-button"
        onClick={() => dispatch(toggleDepartTimeModal())}
        aria-label="Choose your car"
      >
        Choose your car
      </button>
    </div>
  );
};

export default React.memo(ChooseCarButton);
