import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMapView } from '../features/map/mapSlice';
import { VIEWS } from '../constants/states';

export const useMapGestures = (mapInstance) => {
  const dispatch = useDispatch();
  const isDragging = useRef(false);
  const initialTouch = useRef({ x: 0, y: 0 });
  const initialValues = useRef({ heading: 0 });
  
  const currentView = useSelector(state => state.map.currentView);

  const isRotationView = currentView?.name === VIEWS.STATION_VIEW.name || 
                        currentView?.name === VIEWS.ME_VIEW.name ||
                        currentView?.name === VIEWS.AREA_VIEW.name ||
                        currentView?.name === VIEWS.DRIVE_VIEW.name;

  useEffect(() => {
    if (!mapInstance) return;

    const mapDiv = mapInstance.getDiv();
    if (!mapDiv) return;

    const handleRotation = (clientX) => {
      if (!isRotationView) return;
      
      const deltaX = clientX - initialTouch.current.x;
      const rotationFactor = 0.5;
      const newHeading = (initialValues.current.heading + deltaX * rotationFactor) % 360;
      
      requestAnimationFrame(() => {
        mapInstance.setHeading(newHeading);
        dispatch(updateMapView({ heading: newHeading }));
      });
    };

    const handleTouchStart = (e) => {
      if (!isRotationView || e.touches.length !== 1) return;
      
      isDragging.current = true;
      initialTouch.current = { x: e.touches[0].clientX };
      initialValues.current = { heading: mapInstance.getHeading() };

      e.preventDefault();
      e.stopPropagation();
    };

    const handleTouchMove = (e) => {
      if (!isDragging.current || !isRotationView || e.touches.length !== 1) return;

      handleRotation(e.touches[0].clientX);
      e.preventDefault();
      e.stopPropagation();
    };

    const handleMouseDown = (e) => {
      if (!isRotationView) return;
      
      isDragging.current = true;
      initialTouch.current = { x: e.clientX };
      initialValues.current = { heading: mapInstance.getHeading() };

      e.preventDefault();
      e.stopPropagation();
    };

    const handleMouseMove = (e) => {
      if (!isDragging.current || !isRotationView) return;

      handleRotation(e.clientX);
      e.preventDefault();
      e.stopPropagation();
    };

    const handleEnd = () => {
      isDragging.current = false;
    };

    if (isRotationView) {
      mapDiv.addEventListener('touchstart', handleTouchStart, { passive: false });
      mapDiv.addEventListener('touchmove', handleTouchMove, { passive: false });
      mapDiv.addEventListener('touchend', handleEnd);
      mapDiv.addEventListener('touchcancel', handleEnd);
      mapDiv.addEventListener('mousedown', handleMouseDown);
      mapDiv.addEventListener('mousemove', handleMouseMove);
      mapDiv.addEventListener('mouseup', handleEnd);
      mapDiv.addEventListener('mouseleave', handleEnd);

      mapDiv.style.touchAction = 'none';
    }

    return () => {
      mapDiv.removeEventListener('touchstart', handleTouchStart);
      mapDiv.removeEventListener('touchmove', handleTouchMove);
      mapDiv.removeEventListener('touchend', handleEnd);
      mapDiv.removeEventListener('touchcancel', handleEnd);
      mapDiv.removeEventListener('mousedown', handleMouseDown);
      mapDiv.removeEventListener('mousemove', handleMouseMove);
      mapDiv.removeEventListener('mouseup', handleEnd);
      mapDiv.removeEventListener('mouseleave', handleEnd);

      mapDiv.style.touchAction = '';
    };
  }, [dispatch, mapInstance, isRotationView]);
};

export default useMapGestures;
