// src/utils/mapHelpers.js

/**
 * Calculates bounds for a center point and array of locations
 * @param {google.maps.LatLng} center - Center point for the bounds
 * @param {Array} locations - Array of location objects with position property
 * @param {number} padding - Optional padding factor (0-1)
 * @returns {google.maps.LatLngBounds|null}
 */
export const calculateBounds = (center, locations = [], padding = 0.2) => {
  if (!window.google) return null;

  const bounds = new google.maps.LatLngBounds();

  // Add center point if provided
  if (center?.lat && center?.lng) {
    bounds.extend(center);
  }

  // Add all valid locations
  if (locations?.length) {
    locations.forEach(location => {
      if (location?.position?.lat && location?.position?.lng) {
        bounds.extend(location.position);
      }
    });
  }

  // If bounds are empty or invalid, return null
  if (bounds.isEmpty()) return null;

  // Apply padding if specified
  if (padding > 0) {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const latSpan = ne.lat() - sw.lat();
    const lngSpan = ne.lng() - sw.lng();
    
    // Create new padded bounds
    const paddedBounds = new google.maps.LatLngBounds();
    paddedBounds.extend({
      lat: ne.lat() + (latSpan * padding),
      lng: ne.lng() + (lngSpan * padding)
    });
    paddedBounds.extend({
      lat: sw.lat() - (latSpan * padding),
      lng: sw.lng() - (lngSpan * padding)
    });
    
    return paddedBounds;
  }

  return bounds;
};

/**
 * Calculates optimal zoom level for given bounds and map dimensions
 * @param {google.maps.LatLngBounds} bounds
 * @param {{ width: number, height: number }} mapDimensions
 * @returns {number|null}
 */
export const getOptimalZoom = (bounds, mapDimensions) => {
  if (!bounds || !mapDimensions) return null;

  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const latFraction = (ne.lat() - sw.lat()) / 180;
  const lngFraction = (ne.lng() - sw.lng()) / 360;

  const latZoom = Math.floor(
    Math.log(mapDimensions.height / WORLD_DIM.height / latFraction) / Math.LN2
  );
  const lngZoom = Math.floor(
    Math.log(mapDimensions.width / WORLD_DIM.width / lngFraction) / Math.LN2
  );

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
};

/**
 * Gets padding configuration based on bounds span
 * @param {google.maps.LatLngBounds} bounds
 * @param {{ top: number, right: number, bottom: number, left: number }} maxPadding
 * @returns {{ top: number, right: number, bottom: number, left: number }}
 */
export const getAdaptivePadding = (bounds, maxPadding = { top: 100, right: 50, bottom: 100, left: 50 }) => {
  if (!bounds) return maxPadding;

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const latSpan = ne.lat() - sw.lat();
  const lngSpan = ne.lng() - sw.lng();

  return {
    top: Math.min(maxPadding.top, latSpan * 0.3),
    bottom: Math.min(maxPadding.bottom, latSpan * 0.3),
    left: Math.min(maxPadding.left, lngSpan * 0.2),
    right: Math.min(maxPadding.right, lngSpan * 0.2)
  };
};

/**
 * Checks if a point is within bounds
 * @param {google.maps.LatLngBounds} bounds
 * @param {{ lat: number, lng: number }} position
 * @returns {boolean}
 */
export const isWithinBounds = (bounds, position) => {
  if (!bounds || !position?.lat || !position?.lng) return false;
  return bounds.contains(new google.maps.LatLng(position.lat, position.lng));
};

/**
 * Creates a bounds object with padding based on the span of the area
 * @param {google.maps.LatLngBounds} bounds
 * @param {number} paddingFraction
 * @returns {google.maps.LatLngBounds|null}
 */
export const createPaddedBounds = (bounds, paddingFraction = 0.2) => {
  if (!bounds) return null;

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  
  const span = {
    lat: (ne.lat() - sw.lat()) * paddingFraction,
    lng: (ne.lng() - sw.lng()) * paddingFraction
  };

  const paddedBounds = new google.maps.LatLngBounds();
  paddedBounds.extend({
    lat: ne.lat() + span.lat,
    lng: ne.lng() + span.lng
  });
  paddedBounds.extend({
    lat: sw.lat() - span.lat,
    lng: sw.lng() - span.lng
  });

  return paddedBounds;
};
