// src/utils/viewUtils.js
import { VIEWS, ZOOM_THRESHOLDS, TRANSITION_TIMINGS } from '../constants/states';

/**
 * Gets zoom threshold settings for a view with fallback to CITY_VIEW
 * @param {string} viewName - The name of the view
 * @returns {Object} - Zoom threshold settings { min, max, default }
 */
export const getThresholds = (viewName) => {
  const thresholds = ZOOM_THRESHOLDS[viewName];
  if (!thresholds) {
    console.warn(`No zoom thresholds for ${viewName}, using CITY_VIEW`);
    return ZOOM_THRESHOLDS.CITY_VIEW;
  }
  return thresholds;
};

/**
 * Safely clamps a zoom level between min and max values
 * @param {number} zoom - The zoom level to clamp
 * @param {number} min - Minimum zoom level
 * @param {number} max - Maximum zoom level
 * @returns {number} - The clamped zoom level
 */
export const clampZoom = (zoom, min, max) => Math.min(Math.max(zoom, min), max);

/**
 * Gets complete view settings for a specific state
 * @param {string} state - The state name (e.g., 'DISTRICT_VIEW')
 * @param {Object} position - Optional position { lat, lng }
 * @param {Object} options - Optional additional settings
 * @returns {Object} Complete view settings
 */
export const getViewSettingsForState = (state, position = null, options = {}) => {
  const viewSettings = (() => {
    switch (state) {
      case 'DISTRICT_VIEW':
        return {
          ...VIEWS.DISTRICT_VIEW,
          name: VIEWS.DISTRICT_VIEW.name,
          center: position,
          tilt: VIEWS.DISTRICT_VIEW.tilt,
          heading: VIEWS.DISTRICT_VIEW.heading
        };

      case 'AREA_VIEW':
        return {
          ...VIEWS.AREA_VIEW,
          name: VIEWS.AREA_VIEW.name,
          center: position,
          tilt: VIEWS.AREA_VIEW.tilt,
          heading: VIEWS.AREA_VIEW.heading
        };

      case 'STATION_VIEW':
        return {
          ...VIEWS.STATION_VIEW,
          name: VIEWS.STATION_VIEW.name,
          center: position,
          tilt: VIEWS.STATION_VIEW.tilt,
          heading: VIEWS.STATION_VIEW.heading
        };

      case 'ME_VIEW':
        return {
          ...VIEWS.ME_VIEW,
          name: VIEWS.ME_VIEW.name,
          center: position,
          tilt: VIEWS.ME_VIEW.tilt,
          heading: VIEWS.ME_VIEW.heading
        };

      case 'DRIVE_VIEW':
        return {
          ...VIEWS.DRIVE_VIEW,
          name: VIEWS.DRIVE_VIEW.name,
          tilt: VIEWS.DRIVE_VIEW.tilt,
          heading: VIEWS.DRIVE_VIEW.heading
        };

      default:
        return {
          ...VIEWS.CITY_VIEW,
          name: VIEWS.CITY_VIEW.name,
          tilt: VIEWS.CITY_VIEW.tilt,
          heading: VIEWS.CITY_VIEW.heading
        };
    }
  })();

  const thresholds = getThresholds(state);

  return {
    ...viewSettings,
    ...options,
    zoom: options.zoom || thresholds.default,
    ...(options.includeZoomConstraints ? {
      minZoom: thresholds.min,
      maxZoom: thresholds.max
    } : {})
  };
};

/**
 * Validates if a transition between views is allowed
 * @param {string} fromView - Current view name
 * @param {string} toView - Target view name
 * @returns {boolean} - Whether transition is allowed
 */
export const isValidTransition = (fromView, toView) => {
  const currentViewSettings = VIEWS[fromView];
  if (!currentViewSettings?.allowedTransitions) return false;
  return currentViewSettings.allowedTransitions.includes(toView);
};

/**
 * Gets intermediate view settings for staged transitions
 * @param {string} fromView - Starting view name
 * @param {string} toView - Target view name
 * @param {Object} position - Position for the transition
 * @returns {Array} Array of view settings to apply in sequence
 */
export const getStagedTransitionSettings = (fromView, toView, position) => {
  // Direct transition if allowed
  if (isValidTransition(fromView, toView)) {
    return [getViewSettingsForState(toView, position)];
  }

  // Special case: CITY_VIEW to AREA_VIEW through DISTRICT_VIEW
  if (fromView === 'CITY_VIEW' && toView === 'AREA_VIEW') {
    return [
      getViewSettingsForState('DISTRICT_VIEW', position),
      getViewSettingsForState('AREA_VIEW', position)
    ];
  }

  // Default: no valid transition path
  return [];
};

/**
 * Gets timing settings for a view transition
 * @param {string} fromView - Starting view name
 * @param {string} toView - Target view name
 * @returns {Object} Timing settings for the transition
 */
export const getTransitionTiming = (fromView, toView) => {
  // Staged transition timing
  if (fromView === 'CITY_VIEW' && toView === 'AREA_VIEW') {
    return {
      initial: TRANSITION_TIMINGS.INITIAL_DELAY,
      staged: TRANSITION_TIMINGS.STAGED_TRANSITION,
      complete: TRANSITION_TIMINGS.VIEW_CHANGE
    };
  }

  // Default timing
  return {
    initial: TRANSITION_TIMINGS.INITIAL_DELAY,
    complete: TRANSITION_TIMINGS.VIEW_CHANGE
  };
};
