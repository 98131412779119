import { configureStore } from '@reduxjs/toolkit';
import { middleware } from './middleware';
import userReducer from '../features/user/userSlice';
import mapReducer from '../features/map/mapSlice';
import locationReducer from '../features/location/locationSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    map: mapReducer,
    location: locationReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(middleware)
});

export default store;
