// src/components/MotionMenu/index.jsx
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleTripInfoSheet } from '../../features/user/userSlice';
import { USER_STATES } from '../../constants/states';
import { FaRoute, FaClock, FaDollarSign, FaTimes } from 'react-icons/fa';
import './styles.css';

const MotionMenu = () => {
  const dispatch = useDispatch();
  const { 
    departureStation, 
    destinationStation, 
    fareInfo, 
    departureTime,
    showTripInfoSheet,
    userState,
    directions
  } = useSelector(state => ({
    departureStation: state.user.departureStation,
    destinationStation: state.user.destinationStation,
    fareInfo: state.user.fareInfo,
    departureTime: state.user.departureTime,
    showTripInfoSheet: state.user.showTripInfoSheet,
    userState: state.user.userState,
    directions: state.map.directions
  }));

  const shouldShow = userState === USER_STATES.SELECTED_ARRIVAL && 
                    showTripInfoSheet && 
                    fareInfo && 
                    directions?.routes?.[0]?.legs?.[0];

  if (!shouldShow) return null;

  const route = directions.routes[0].legs[0];

  return (
    <div className="motion-menu">
      <div className="menu-header">
        <h2 className="menu-title">Trip Details</h2>
        <button 
          className="close-button"
          onClick={() => dispatch(toggleTripInfoSheet())}
          aria-label="Close menu"
        >
          <FaTimes />
        </button>
      </div>

      <div className="station-info">
        <div className="station-dot departure" />
        <div>
          <p className="text-sm text-gray-400">From</p>
          <p className="font-medium">{departureStation.place}</p>
        </div>
      </div>

      <div className="station-info">
        <div className="station-dot arrival" />
        <div>
          <p className="text-sm text-gray-400">To</p>
          <p className="font-medium">{destinationStation.place}</p>
        </div>
      </div>

      <div className="info-grid">
        <div className="info-card">
          <div className="flex items-center space-x-3 mb-2">
            <FaRoute className="text-blue-500" />
            <span className="text-sm text-gray-400">Distance</span>
          </div>
          <p className="text-lg font-medium">{route.distance.text}</p>
        </div>

        <div className="info-card">
          <div className="flex items-center space-x-3 mb-2">
            <FaClock className="text-blue-500" />
            <span className="text-sm text-gray-400">Duration</span>
          </div>
          <p className="text-lg font-medium">{route.duration.text}</p>
        </div>
      </div>

      <div className="fare-card">
        <div className="flex items-center space-x-3 mb-4">
          <FaDollarSign className="text-green-500" />
          <span className="text-lg font-medium">Fare Details</span>
        </div>

        <div>
          <p className="text-sm text-gray-400">Our Fare</p>
          <p className="text-2xl font-semibold text-green-500">
            HK${fareInfo.ourFare.toFixed(2)}
          </p>

          <div className="mt-4">
            <p className="text-sm text-gray-400">Standard Taxi Fare</p>
            <p className="text-lg text-gray-500 line-through">
              HK${fareInfo.taxiFareEstimate.toFixed(2)}
            </p>
          </div>

          <p className="mt-4 text-sm text-green-400">
            Save HK${(fareInfo.taxiFareEstimate - fareInfo.ourFare).toFixed(2)}
          </p>
        </div>
      </div>

      <button className="confirm-button">
        Confirm Booking
      </button>
    </div>
  );
};

export default React.memo(MotionMenu);
