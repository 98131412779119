import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { setDepartureTimeAndTransition, toggleDepartTimeModal } from '../../features/user/userSlice';
import { getNextDepartureTimes } from '../../utils/timeHelpers';
import './styles.css';

const DepartTimeModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.user.isDepartTimeModalOpen);
  
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [timeOptions, setTimeOptions] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setSelectedTime('');
      setSelectedType('');
      setTimeOptions(getNextDepartureTimes(3, 45));
    }
  }, [isOpen]);

  const handleClose = () => {
    dispatch(toggleDepartTimeModal());
  };

  const handleConfirm = () => {
    if (!selectedTime || !selectedType) return;
    
    dispatch(setDepartureTimeAndTransition({
      selectedTime: parseInt(selectedTime),
      bookingType: selectedType
    }));
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        className="modal-overlay"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={handleClose}
      >
        <motion.div 
          className="modal-content"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          onClick={e => e.stopPropagation()}
        >
          <div className="modal-header">
            <h2>Choose Departure Time</h2>
            <button 
              className="close-button"
              onClick={handleClose}
              aria-label="Close modal"
            >
              ×
            </button>
          </div>

          <div className="time-selector">
            <select 
              value={selectedTime} 
              onChange={(e) => setSelectedTime(e.target.value)}
              className="time-select"
            >
              <option value="">Select a time</option>
              {timeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="booking-options">
            <div 
              className={`booking-option ${selectedType === 'pay-as-you-go' ? 'selected' : ''}`}
              onClick={() => setSelectedType('pay-as-you-go')}
            >
              <h3>Pay As You Go</h3>
              <p>HKD$1 per minute</p>
              <p>Maximum HKD$600 per day</p>
            </div>

            <div 
              className={`booking-option ${selectedType === 'station-to-station' ? 'selected' : ''}`}
              onClick={() => setSelectedType('station-to-station')}
            >
              <h3>Station to Station</h3>
              <p>Fixed price based on distance</p>
              <p>View fare after selecting destination</p>
            </div>
          </div>

          <button 
            className="confirm-button"
            onClick={handleConfirm}
            disabled={!selectedTime || !selectedType}
          >
            Continue to Destination Selection
          </button>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default DepartTimeModal;
