// src/App.jsx
import React from 'react';
import { useSelector } from 'react-redux';
// Components
import MapContainer from './components/MapContainer';
import ViewBar from './components/ViewBar';
import InfoBox from './components/InfoBox';
import ErrorBoundary from './components/ErrorBoundary';
import ChooseCarButton from './components/ChooseCarButton';
import BookTripButton from './components/BookTripButton';
import DepartTime from './components/DepartTime';
import MotionMenu from './components/MotionMenu';
import './App.css';

const App = () => {
  const { departureStation, destinationStation } = useSelector((state) => state.user);

  return (
    <ErrorBoundary>
      <div className="relative h-screen w-full overflow-hidden">
        {/* Base Layer - Map */}
        <div className="absolute inset-0 z-0">
          <MapContainer />
        </div>
        
        {/* UI Overlay Layer */}
        <div className="absolute inset-0 z-50 pointer-events-none">
          {/* ViewBar - Always visible */}
          <div className="pointer-events-auto">
            <ViewBar />
          </div>

          {/* Info Boxes */}
          {(departureStation || destinationStation) && (
            <div className="absolute top-20 left-0 right-0 px-4 pointer-events-auto">
              <InfoBox />
            </div>
          )}

          {/* Bottom Actions */}
          <div className="absolute bottom-0 left-0 right-0 px-4 pb-4 pointer-events-auto">
            <ChooseCarButton />
            <BookTripButton />
          </div>
        </div>

        {/* Modal Layer - Highest Z-index */}
        <div className="absolute inset-0 z-[100] pointer-events-none">
          <div className="pointer-events-auto">
            <DepartTime />
            <MotionMenu />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default App;
