// src/constants/states.js
export const USER_STATES = {
  SELECTING_DEPARTURE: 'SELECTING_DEPARTURE',
  SELECTED_DEPARTURE: 'SELECTED_DEPARTURE',
  SELECTING_ARRIVAL: 'SELECTING_ARRIVAL',
  SELECTED_ARRIVAL: 'SELECTED_ARRIVAL',
  SELECTING_DEPARTURE_TIME: 'SELECTING_DEPARTURE_TIME',
  DISPLAY_FARE: 'DISPLAY_FARE'
};

export const ZOOM_THRESHOLDS = {
  CITY_VIEW: { min: 11, max: 12, default: 11 },
  DISTRICT_VIEW: { min: 13, max: 14, default: 13 },
  AREA_VIEW: { min: 15, max: 16, default: 15 },
  STATION_VIEW: { min: 17, max: 20, default: 18 },
  ME_VIEW: { min: 11, max: 16, default: 15 },
  DRIVE_VIEW: { min: 13, max: 15, default: 14 }
};

export const TRANSITION_TIMINGS = {
  INITIAL_DELAY: 50,
  VIEW_CHANGE: 500,
  STAGED_TRANSITION: 600,
  ZOOM_DELAY: 100,
  STATE_RESET: 500
};

export const VIEWS = {
  CITY_VIEW: {
    name: 'CITY_VIEW',
    center: { lat: 22.236, lng: 114.191 },
    zoom: ZOOM_THRESHOLDS.CITY_VIEW.default,
    tilt: 0,
    heading: 0,
    allowedTransitions: ['DISTRICT_VIEW', 'ME_VIEW']
  },
  DISTRICT_VIEW: {
    name: 'DISTRICT_VIEW',
    zoom: ZOOM_THRESHOLDS.DISTRICT_VIEW.default,
    tilt: 45,
    heading: 0,
    allowedTransitions: ['CITY_VIEW', 'AREA_VIEW', 'STATION_VIEW']
  },
  AREA_VIEW: {
    name: 'AREA_VIEW',
    zoom: ZOOM_THRESHOLDS.AREA_VIEW.default,
    tilt: 45,
    heading: 0,
    allowedTransitions: ['DISTRICT_VIEW', 'STATION_VIEW']
  },
  STATION_VIEW: {
    name: 'STATION_VIEW',
    zoom: ZOOM_THRESHOLDS.STATION_VIEW.default,
    tilt: 60,
    heading: 0,
    allowedTransitions: ['AREA_VIEW', 'DISTRICT_VIEW']
  },
  ME_VIEW: {
    name: 'ME_VIEW',
    zoom: ZOOM_THRESHOLDS.ME_VIEW.default,
    tilt: 45,
    heading: 0,
    allowedTransitions: ['CITY_VIEW']
  },
  DRIVE_VIEW: {
    name: 'DRIVE_VIEW',
    zoom: ZOOM_THRESHOLDS.DRIVE_VIEW.default,
    tilt: 45,
    heading: 0,
    allowedTransitions: ['STATION_VIEW']
  }
};

// Animation settings for smooth transitions
export const MAP_ANIMATION = {
  DEFAULT_DURATION: 800,
  EASING: 'cubic-bezier(0.4, 0, 0.2, 1)',
  BOUNDS_PADDING: {
    top: 100,
    right: 50,
    bottom: 100,
    left: 50
  }
};

export const MAP_BOUNDARIES = {
  HK: {
    north: 22.5619,
    south: 22.1534,
    west: 113.8876,
    east: 114.4349
  }
};

// Base options for different map views
const baseMapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  zoomControl: false,
  keyboardShortcuts: false,
  rotateControl: false,
  fullscreenControl: false
};

export const MAP_OPTIONS = {
  CITY: {
    ...baseMapOptions,
    gestureHandling: 'greedy',
    draggable: true,
    scrollwheel: true,
    tilt: 0
  },
  DISTRICT: {
    ...baseMapOptions,
    gestureHandling: 'greedy',
    draggable: true,
    scrollwheel: true,
    tilt: 45
  },
  AREA: {
    ...baseMapOptions,
    gestureHandling: 'greedy',
    draggable: true,
    scrollwheel: true,
    tilt: 45
  },
  STATION: {
    ...baseMapOptions,
    gestureHandling: 'none',
    draggable: false,
    scrollwheel: false,
    tilt: 60,
    disableDoubleClickZoom: true
  },
  ME: {
    ...baseMapOptions,
    gestureHandling: 'greedy',
    draggable: true,
    scrollwheel: true,
    tilt: 45
  },
  DRIVE: {
    ...baseMapOptions,
    gestureHandling: 'cooperative',
    draggable: false,
    scrollwheel: false,
    tilt: 45
  }
};

export const VIEWPORT = {
  MOBILE: 640,
  TABLET: 768,
  DESKTOP: 1024
};

export const Z_INDEX = {
  MAP: 0,
  BASE: 10,
  UI: 20,
  INFO: 30,
  BUTTON: 40,
  MODAL: 50
};

export const MAP_DEFAULTS = {
  CENTER: { lat: 22.236, lng: 114.191 },
  ZOOM: ZOOM_THRESHOLDS.CITY_VIEW.default,
  TILT: 45,
  HEADING: 0
};
