// src/features/location/locationSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CIRCLE_DISTANCES } from '../../constants/config';

export const getCurrentLocation = createAsyncThunk(
  'location/getCurrentLocation',
  async (_, { rejectWithValue }) => {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        });
      });

      return {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  userLocation: null,
  stationLocations: [],
  districts: [],
  showCircles: false,
  circleDistances: CIRCLE_DISTANCES,
  isLocating: false,
  error: null,
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setStationLocations: (state, action) => {
      state.stationLocations = action.payload;
    },
    setDistricts: (state, action) => {
      state.districts = action.payload;
    },
    toggleCircles: (state, action) => {
      state.showCircles = action.payload ?? !state.showCircles;
    },
    clearLocationState: (state) => {
      state.userLocation = null;
      state.showCircles = false;
      state.isLocating = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentLocation.pending, (state) => {
        state.isLocating = true;
        state.error = null;
      })
      .addCase(getCurrentLocation.fulfilled, (state, action) => {
        state.userLocation = action.payload;
        state.isLocating = false;
      })
      .addCase(getCurrentLocation.rejected, (state, action) => {
        state.error = action.payload;
        state.isLocating = false;
        state.userLocation = null;
      });
  },
});

export const {
  setStationLocations,
  setDistricts,
  toggleCircles,
  clearLocationState,
} = locationSlice.actions;

export default locationSlice.reducer;
