// src/components/DistrictMarkers/index.jsx
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayView } from '@react-google-maps/api';
import { setSelectedDistrict, setView } from '../../features/map/mapSlice';
import { getViewSettingsForState } from '../../utils/viewUtils';
import { VIEWS, ZOOM_THRESHOLDS } from '../../constants/states';
import DistrictMarkerSVG from './DistrictMarkerSVG';
import './styles.css';

const DistrictMarkers = () => {
  const dispatch = useDispatch();
  
  const {
    districts,
    selectedDistrict,
    mapInstance,
    currentView,
    isTransitioning
  } = useSelector(state => ({
    districts: state.location.districts,
    selectedDistrict: state.map.selectedDistrict,
    mapInstance: state.map.mapInstance,
    currentView: state.map.currentView,
    isTransitioning: state.map.isTransitioning
  }));

  const handleDistrictClick = useCallback((district) => {
    if (!mapInstance || isTransitioning) return;
    
    console.log('District clicked:', district.name);
    console.log('Current view:', currentView?.name);
    
    dispatch(setSelectedDistrict(district));

    // If in AREA_VIEW, transition back to DISTRICT_VIEW
    if (currentView?.name === VIEWS.AREA_VIEW.name) {
      console.log('Transitioning from AREA_VIEW to DISTRICT_VIEW');
      const viewSettings = {
        ...getViewSettingsForState('DISTRICT_VIEW', district.position),
        zoom: ZOOM_THRESHOLDS.DISTRICT_VIEW.default
      };
      console.log('DISTRICT_VIEW settings:', viewSettings);
      dispatch(setView(viewSettings));
      return;
    }

    // From any other view (including CITY_VIEW), go directly to AREA_VIEW
    console.log('Transitioning to AREA_VIEW');
    const viewSettings = {
      ...getViewSettingsForState('AREA_VIEW', district.position),
      zoom: ZOOM_THRESHOLDS.AREA_VIEW.default
    };
    console.log('AREA_VIEW settings:', viewSettings);
    dispatch(setView(viewSettings));
  }, [dispatch, mapInstance, currentView, isTransitioning]);

  const visibleDistricts = useMemo(() => {
    if (!districts?.length) return [];
    
    // Hide markers in these views
    if ([
      VIEWS.STATION_VIEW.name,
      VIEWS.ME_VIEW.name,
      VIEWS.DRIVE_VIEW.name
    ].includes(currentView?.name)) {
      return [];
    }

    // In AREA_VIEW or DISTRICT_VIEW, only show selected district
    if ((currentView?.name === VIEWS.AREA_VIEW.name || 
         currentView?.name === VIEWS.DISTRICT_VIEW.name) && 
        selectedDistrict) {
      return districts.filter(d => d.id === selectedDistrict.id);
    }
    
    // In CITY_VIEW show all districts
    return districts;
    
  }, [districts, currentView, selectedDistrict]);

  if (!visibleDistricts.length) return null;

  return (
    <>
      {visibleDistricts.map((district) => (
        <OverlayView
          key={district.id}
          position={district.position}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div 
            className="district-marker"
            onClick={() => handleDistrictClick(district)}
            role="button"
            tabIndex={0}
            aria-label={`District: ${district.name}`}
            aria-expanded={selectedDistrict?.id === district.id}
            data-active={selectedDistrict?.id === district.id}
          >
            <DistrictMarkerSVG
              name={district.name}
              isSelected={selectedDistrict?.id === district.id}
            />
          </div>
        </OverlayView>
      ))}
    </>
  );
};

export default React.memo(DistrictMarkers);
