import React from 'react';

const StationMarkerSVG = ({ name, type = 'default', isSelected }) => {
  // Refined color palette to match app theme
  const colors = {
    default: {
      fill: 'rgba(31, 41, 55, 0.95)',
      stroke: '#FFFFFF',
      label: 'rgba(31, 41, 55, 0.95)'
    },
    departure: {
      fill: 'rgba(37, 99, 235, 0.95)', // Matches selected district color
      stroke: '#FFFFFF',
      label: 'rgba(37, 99, 235, 0.95)'
    },
    destination: {
      fill: 'rgba(16, 185, 129, 0.95)', // Matches success/confirmation color
      stroke: '#FFFFFF',
      label: 'rgba(16, 185, 129, 0.95)'
    }
  };

  const currentColors = isSelected ? colors.departure : colors[type];

  // Calculate label width based on text length
  const labelWidth = name ? Math.max(name.length * 8, 60) : 0;
  const markerSize = 28; // Smaller marker size

  return (
    <svg
      width={Math.max(markerSize, labelWidth)}
      height={markerSize + 24}
      viewBox={`0 0 ${Math.max(markerSize, labelWidth)} ${markerSize + 24}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Marker Base */}
      <g transform={`translate(${(Math.max(markerSize, labelWidth) - markerSize) / 2}, 0)`}>
        {/* Drop Shadow */}
        <path
          d="M14 0C6.268 0 0 6.268 0 14C0 24.5 14 40 14 40C14 40 28 24.5 28 14C28 6.268 21.732 0 14 0Z"
          fill="rgba(0,0,0,0.15)"
          transform="translate(1, 1)"
        />
        
        {/* Main Marker */}
        <path
          d="M14 0C6.268 0 0 6.268 0 14C0 24.5 14 40 14 40C14 40 28 24.5 28 14C28 6.268 21.732 0 14 0Z"
          fill={currentColors.fill}
        />

        {/* Center Circle */}
        <circle
          cx="14"
          cy="14"
          r="6"
          fill={currentColors.stroke}
          stroke={currentColors.fill}
          strokeWidth="2"
        />

        {/* Selection Indicator */}
        {isSelected && (
          <circle
            cx="14"
            cy="14"
            r="10"
            stroke={currentColors.fill}
            strokeWidth="2"
            fill="none"
            className="active-pulse"
          />
        )}
      </g>

      {/* Label - Always visible */}
      {name && (
        <foreignObject
          x={(Math.max(markerSize, labelWidth) - labelWidth) / 2}
          y={markerSize + 4}
          width={labelWidth}
          height="20"
        >
          <div
            style={{
              backgroundColor: currentColors.label,
              color: '#FFFFFF',
              fontSize: '12px',
              fontFamily: 'system-ui, -apple-system, sans-serif',
              fontWeight: '500',
              padding: '3px 8px',
              borderRadius: '4px',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              backdropFilter: 'blur(4px)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}
          >
            {name}
          </div>
        </foreignObject>
      )}
    </svg>
  );
};

export default React.memo(StationMarkerSVG);
