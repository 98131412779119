// src/components/MapContainer/index.jsx
import React, { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GoogleMap,
  DirectionsRenderer,
  useJsApiLoader
} from '@react-google-maps/api';
import { motion, AnimatePresence } from 'framer-motion';
import { useMapGestures } from '../../hooks/useMapGestures';
import { useMapTransitions } from '../../hooks/useMapTransitions';
import { VIEWS, ZOOM_THRESHOLDS, MAP_BOUNDARIES, MAP_OPTIONS } from '../../constants/states';
import { calculateBounds, getAdaptivePadding } from '../../utils/mapHelpers';

import {
  setMapInstance,
  updateMapView,
  selectMapState,
  setDirections
} from '../../features/map/mapSlice';

import {
  getCurrentLocation,
  setStationLocations,
  setDistricts,
  toggleCircles,
} from '../../features/location/locationSlice';

// Components
import StationMarkers from '../StationMarkers';
import DistrictMarkers from '../DistrictMarkers';
import UserOverlay from '../UserOverlay';
import UserCircles from '../UserCircles';
import LoadingSpinner from '../LoadingSpinner';

const GOOGLE_MAPS_API_KEY = "AIzaSyA8rDrxBzMRlgbA7BQ2DoY31gEXzZ4Ours";
const mapId = "94527c02bbb6243";
const libraries = ["places"];

const mapContainerStyle = {
  width: '100%',
  height: '100vh',
};

const defaultCenter = { lat: 22.236, lng: 114.191 };
const defaultZoom = ZOOM_THRESHOLDS.CITY_VIEW.default;

// Base map options without zoom settings
const baseMapOptions = {
  mapId,
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  zoomControl: false,
  disableDefaultUI: true,
  clickableIcons: false,
  backgroundColor: '#161616',
  styles: [
    {
      featureType: 'all',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    }
  ],
  restriction: {
    latLngBounds: MAP_BOUNDARIES.HK,
    strictBounds: true
  }
};

// Get initial map options with widest possible zoom range
const getInitialMapOptions = () => {
  const minZoom = Math.min(...Object.values(ZOOM_THRESHOLDS).map(t => t.min));
  const maxZoom = Math.max(...Object.values(ZOOM_THRESHOLDS).map(t => t.max));

  return {
    ...baseMapOptions,
    ...MAP_OPTIONS.CITY,
    minZoom,
    maxZoom
  };
};

// Get view-specific options without zoom constraints
const getMapOptions = (currentView) => {
  if (!currentView?.name) {
    return getInitialMapOptions();
  }

  const baseViewName = currentView.name.split('_')[0];
  const viewOptions = MAP_OPTIONS[baseViewName];
  
  if (!viewOptions) {
    console.warn(`Invalid view configuration for: ${currentView.name}`);
    return getInitialMapOptions();
  }

  return {
    ...baseMapOptions,
    ...viewOptions,
    keyboardShortcuts: false
  };
};

const MapContainer = () => {
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const directionsServiceRef = useRef(null);
  const boundsRef = useRef(null);
  const transitionTimeoutRef = useRef(null);

  const { isLoaded: mapsLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const { 
    isLoaded: mapInstanceLoaded,
    error,
    directions,
    currentView,
    mapInstance,
    selectedDistrict,
    isTransitioning
  } = useSelector(selectMapState);

  const {
    userLocation,
    stationLocations,
    districts,
    showCircles,
  } = useSelector(state => ({
    userLocation: state.location.userLocation,
    stationLocations: state.location.stationLocations,
    districts: state.location.districts,
    showCircles: state.location.showCircles,
  }));

  const {
    departureStation,
    destinationStation,
    userState
  } = useSelector(state => ({
    departureStation: state.user.departureStation,
    destinationStation: state.user.destinationStation,
    userState: state.user.userState
  }));

  useMapGestures(mapInstance);
  useMapTransitions(mapInstance);

  // Initialize DirectionsService
  useEffect(() => {
    if (mapsLoaded && !directionsServiceRef.current) {
      directionsServiceRef.current = new window.google.maps.DirectionsService();
    }
  }, [mapsLoaded]);

  // Initialize map bounds
  useEffect(() => {
    if (mapRef.current && !boundsRef.current) {
      boundsRef.current = new window.google.maps.LatLngBounds(
        { lat: MAP_BOUNDARIES.HK.south, lng: MAP_BOUNDARIES.HK.west },
        { lat: MAP_BOUNDARIES.HK.north, lng: MAP_BOUNDARIES.HK.east }
      );
    }
  }, [mapRef.current]);

  // Handle map load
  const handleMapLoad = useCallback((map) => {
    if (!map) return;

    // Initialize with widest possible zoom range
    const initialOptions = getInitialMapOptions();
    map.setOptions(initialOptions);
    
    mapRef.current = map;
    dispatch(setMapInstance(map));
    
    // Set initial bounds
    if (boundsRef.current) {
      map.fitBounds(boundsRef.current);
    }
    
    loadGeoData();
    dispatch(getCurrentLocation());
  }, [dispatch]);

  // Calculate route when stations are selected
  useEffect(() => {
    if (!directionsServiceRef.current || !departureStation || !destinationStation || userState !== 'SELECTED_ARRIVAL') return;

    const calculateRoute = async () => {
      try {
        const result = await directionsServiceRef.current.route({
          origin: departureStation.position,
          destination: destinationStation.position,
          travelMode: google.maps.TravelMode.DRIVING,
        });

        dispatch(setDirections(result));

        if (mapRef.current) {
          const bounds = calculateBounds(null, [departureStation, destinationStation]);
          if (bounds) {
            const padding = getAdaptivePadding(bounds);
            mapRef.current.fitBounds(bounds, { padding });
          }
        }
      } catch (error) {
        console.error('Route calculation failed:', error);
      }
    };

    calculateRoute();
  }, [departureStation, destinationStation, userState, dispatch]);

  // Load GeoJSON data
  const loadGeoData = async () => {
    try {
      const [stationsRes, districtsRes] = await Promise.all([
        fetch('/stations.geojson'),
        fetch('/districts.geojson'),
      ]);

      if (!stationsRes.ok || !districtsRes.ok) {
        throw new Error('Failed to fetch GeoJSON data');
      }

      const [stationsData, districtsData] = await Promise.all([
        stationsRes.json(),
        districtsRes.json()
      ]);

      if (stationsData.features?.length) {
        const processedStations = stationsData.features.map(feature => ({
          id: feature.id || Math.random().toString(36),
          place: feature.properties.Place,
          position: {
            lat: feature.geometry.coordinates[1],
            lng: feature.geometry.coordinates[0],
          },
          district: feature.properties.District,
          address: feature.properties.Address,
        }));
        dispatch(setStationLocations(processedStations));
      }

      if (districtsData.features?.length) {
        const processedDistricts = districtsData.features.map(feature => ({
          id: feature.id || Math.random().toString(36),
          name: feature.properties.District,
          position: {
            lat: feature.geometry.coordinates[1],
            lng: feature.geometry.coordinates[0],
          },
          description: feature.properties.Description,
        }));
        dispatch(setDistricts(processedDistricts));
      }
    } catch (error) {
      console.error('Error loading GeoJSON:', error);
    }
  };

  // Handle view changes
  useEffect(() => {
    if (!mapRef.current || !currentView || isTransitioning) return;

    const map = mapRef.current;
    
    if (transitionTimeoutRef.current) {
      clearTimeout(transitionTimeoutRef.current);
    }

    const handleTransition = async () => {
      try {
        // Clear zoom constraints first
        map.setOptions({
          minZoom: undefined,
          maxZoom: undefined
        });

        await new Promise(resolve => setTimeout(resolve, 50));

        // Set base options without zoom
        const options = getMapOptions(currentView);
        map.setOptions(options);

        // Handle district-specific transitions
        if (selectedDistrict && 
            (currentView.name === VIEWS.DISTRICT_VIEW.name || 
             currentView.name === VIEWS.AREA_VIEW.name)) {
          
          // Pan to district
          await new Promise(resolve => {
            const listener = map.addListener('idle', () => {
              google.maps.event.removeListener(listener);
              resolve();
            });
            map.panTo(selectedDistrict.position);
          });

          const districtStations = stationLocations.filter(
            station => station.district === selectedDistrict.name
          );

          if (districtStations.length) {
            const bounds = calculateBounds(selectedDistrict.position, districtStations);
            if (bounds) {
              const padding = getAdaptivePadding(bounds);
              await new Promise(resolve => {
                const listener = map.addListener('idle', () => {
                  google.maps.event.removeListener(listener);
                  resolve();
                });
                map.fitBounds(bounds, { padding });
              });
            }
          }
        }

        // Wait before setting zoom
        await new Promise(resolve => setTimeout(resolve, 100));

        // Set zoom level
        const thresholds = ZOOM_THRESHOLDS[currentView.name];
        if (thresholds) {
          await new Promise(resolve => {
            const listener = map.addListener('idle', () => {
              google.maps.event.removeListener(listener);
              resolve();
            });
            map.setZoom(thresholds.default);
          });

          // Finally set zoom constraints
          await new Promise(resolve => setTimeout(resolve, 100));
          map.setOptions({
            minZoom: thresholds.min,
            maxZoom: thresholds.max
          });
        }

        // Update state after everything is done
        dispatch(updateMapView({
          center: map.getCenter().toJSON(),
          zoom: map.getZoom(),
          tilt: map.getTilt(),
          heading: map.getHeading()
        }));

      } catch (error) {
        console.error('View transition error:', error);
      }
    };

    handleTransition();

  }, [currentView, selectedDistrict, stationLocations, isTransitioning, dispatch]);

  // Cleanup
  useEffect(() => {
    return () => {
      if (transitionTimeoutRef.current) {
        clearTimeout(transitionTimeoutRef.current);
      }
    };
  }, []);

  if (loadError) {
    return <div className="text-red-500">Error loading maps</div>;
  }

  if (!mapsLoaded) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="relative w-full h-screen overflow-hidden bg-gray-900">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={defaultCenter}
        zoom={defaultZoom}
        options={getInitialMapOptions()}
        onLoad={handleMapLoad}
      >
        {mapRef.current && (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute inset-0"
            >
              {userLocation && (
                <>
                  <UserOverlay />
                  {showCircles && <UserCircles />}
                </>
              )}

              {districts.length > 0 && (
                <DistrictMarkers />
              )}

              {stationLocations.length > 0 && (
                <StationMarkers />
              )}

              {directions && (
                <DirectionsRenderer
                  directions={directions}
                  options={{
                    suppressMarkers: true,
                    polylineOptions: {
                      strokeColor: '#3B82F6',
                      strokeOpacity: 0.8,
                      strokeWeight: 4,
                    },
                  }}
                />
              )}
            </motion.div>
          </AnimatePresence>
        )}
      </GoogleMap>

      {error && (
        <div className="fixed bottom-4 left-4 right-4 bg-red-500/10 border border-red-500/20 rounded-lg p-4 text-red-400">
          {error}
        </div>
      )}
    </div>
  );
};

export default React.memo(MapContainer);
