// src/components/InfoBox/index.jsx
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaTimes, FaClock } from 'react-icons/fa';
import { clearDeparture, clearDestination } from '../../features/user/userSlice';
import { setView } from '../../features/map/mapSlice';
import { VIEWS } from '../../constants/states';
import './styles.css';

const InfoBox = () => {
  const dispatch = useDispatch();
  
  const {
    departureStation,
    destinationStation,
    departureTime,
    fareInfo,
    directions
  } = useSelector(state => ({
    departureStation: state.user.departureStation,
    destinationStation: state.user.destinationStation,
    departureTime: state.user.departureTime,
    fareInfo: state.user.fareInfo,
    directions: state.map.directions
  }));

  const handleClear = useCallback((type) => {
    const view = {
      ...VIEWS.CITY_VIEW,
      transition: {
        duration: 800
      }
    };

    if (type === 'departure') {
      dispatch(clearDeparture());
    } else {
      dispatch(clearDestination());
    }
    dispatch(setView(view));
  }, [dispatch]);

  const formatTime = useCallback((timestamp) => {
    if (!timestamp) return null;
    return new Date(parseInt(timestamp)).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  }, []);

  const getArrivalTime = useCallback(() => {
    if (!departureTime) return null;
    
    const durationInSeconds = fareInfo?.durationValue || 
      directions?.routes?.[0]?.legs?.[0]?.duration?.value;
    
    if (!durationInSeconds) return null;

    const arrival = new Date(parseInt(departureTime));
    arrival.setSeconds(arrival.getSeconds() + durationInSeconds);
    return arrival;
  }, [departureTime, fareInfo, directions]);

  const arrivalTime = getArrivalTime();

  if (!departureStation && !destinationStation) return null;

  return (
    <div className="info-boxes-container">
      {departureStation && (
        <div className="info-box">
          <div className="info-box-content">
            <div className="info-content">
              <span className="station-label">Departure</span>
              <span className="station-name">{departureStation.place}</span>
              {departureTime && (
                <div className="time-info">
                  <FaClock />
                  <span>{formatTime(departureTime)}</span>
                </div>
              )}
            </div>
            <button
              className="clear-button"
              onClick={() => handleClear('departure')}
              aria-label="Clear departure"
            >
              <FaTimes />
            </button>
          </div>
        </div>
      )}

      {destinationStation && (
        <div className="info-box">
          <div className="info-box-content">
            <div className="info-content">
              <span className="station-label">Arrival</span>
              <span className="station-name">{destinationStation.place}</span>
              {arrivalTime && (
                <div className="time-info">
                  <FaClock />
                  <span>{arrivalTime.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                  })}</span>
                  {directions?.routes?.[0]?.legs?.[0]?.duration?.text && (
                    <span className="duration-text">
                      ({directions.routes[0].legs[0].duration.text})
                    </span>
                  )}
                </div>
              )}
            </div>
            <button
              className="clear-button"
              onClick={() => handleClear('destination')}
              aria-label="Clear arrival"
            >
              <FaTimes />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(InfoBox);
