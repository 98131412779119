import React from 'react';

const DistrictMarkerSVG = ({ name, isSelected }) => {
  // Calculate dimensions based on text length with safe spacing
  const textLength = name.length * 7.2;
  const height = 32;
  const iconSize = 20; // Size of the +/- button area
  const horizontalPadding = 12; // Padding on sides
  const spacing = 8; // Space between text and icon
  const width = textLength + iconSize + (horizontalPadding * 2) + spacing;
  
  const colors = {
    default: {
      background: 'rgba(17, 24, 39, 0.95)',
      border: 'rgba(75, 85, 99, 0.2)',
      text: '#FFFFFF',
      icon: {
        background: 'rgba(55, 65, 81, 0.95)',
        symbol: '#FFFFFF'
      }
    },
    selected: {
      background: 'rgba(37, 99, 235, 0.95)',
      border: 'rgba(59, 130, 246, 0.3)',
      text: '#FFFFFF',
      icon: {
        background: 'rgba(29, 78, 216, 0.95)',
        symbol: '#FFFFFF'
      }
    }
  };

  const currentColors = isSelected ? colors.selected : colors.default;
  const cornerRadius = 8;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Background with subtle border */}
      <rect
        x="0.5"
        y="0.5"
        width={width - 1}
        height={height - 1}
        rx={cornerRadius}
        fill={currentColors.background}
        stroke={currentColors.border}
        strokeWidth="1"
      />

      {/* Text content - Left aligned */}
      <text
        x={horizontalPadding}
        y={height / 2}
        dominantBaseline="middle"
        fill={currentColors.text}
        fontFamily="system-ui, -apple-system, sans-serif"
        fontSize="14"
        fontWeight="500"
        letterSpacing="0.01em"
      >
        {name}
      </text>

      {/* State indicator circle - Right aligned */}
      <circle
        cx={width - horizontalPadding - (iconSize / 2)}
        cy={height / 2}
        r={iconSize / 2}
        fill={currentColors.icon.background}
      />

      {/* Plus/Minus symbol - Centered in circle */}
      <text
        x={width - horizontalPadding - (iconSize / 2)}
        y={height / 2 + 1}
        textAnchor="middle"
        dominantBaseline="middle"
        fill={currentColors.icon.symbol}
        fontSize="16"
        fontWeight="500"
      >
        {isSelected ? '−' : '+'}
      </text>

      {/* Selection indicator */}
      {isSelected && (
        <rect
          x="0.5"
          y="0.5"
          width={width - 1}
          height={height - 1}
          rx={cornerRadius}
          fill="none"
          stroke="rgba(255, 255, 255, 0.1)"
          strokeWidth="1"
        />
      )}
    </svg>
  );
};

export default React.memo(DistrictMarkerSVG);
