// src/constants/config.js
export const PEAK_HOURS = [
  { start: 8, end: 10 },
  { start: 18, end: 20 },
];

export const CIRCLE_DISTANCES = [500, 1000];

export const FARE_CONFIG = {
  BASE_TAXI_FARE: 24,
  PEAK_HOUR_MIN_FARE: 65,
  OFF_PEAK_MIN_FARE: 35,
  DISTANCE_INCREMENT: 200,
  OUR_FARE_MULTIPLIER: 0.7,
};