import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { USER_STATES, VIEWS, ZOOM_THRESHOLDS } from '../../constants/states';
import { calculateFare } from '../../utils/fareCalculator';
import { setView, updateMapView } from '../map/mapSlice';

export const processRouteInformation = createAsyncThunk(
  'user/processRouteInformation',
  async ({ route, isPeakHour }) => {
    const fareInfo = calculateFare(
      route.distance.value,
      route.duration.value,
      isPeakHour
    );

    return {
      ...fareInfo,
      distance: route.distance.text,
      duration: route.duration.text,
      distanceValue: route.distance.value,
      durationValue: route.duration.value,
    };
  }
);

export const setDepartureTimeAndTransition = ({ selectedTime, bookingType }) => (dispatch) => {
  dispatch(setDepartureTime({ selectedTime, bookingType }));
  dispatch(setView({
    ...VIEWS.CITY_VIEW,
    name: VIEWS.CITY_VIEW.name,
    zoom: ZOOM_THRESHOLDS.CITY_VIEW,
    tilt: VIEWS.CITY_VIEW.tilt,
    heading: 0
  }));
};

const initialState = {
  userState: USER_STATES.SELECTING_DEPARTURE,
  departureStation: null,
  destinationStation: null,
  departureTime: null,
  bookingType: null,
  fareInfo: null,
  isDepartTimeModalOpen: false,
  isRouteDetailsModalOpen: false,
  showTripInfoSheet: false,
  isLoading: false,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setDepartureStation: (state, action) => {
      state.departureStation = action.payload;
      if (state.userState === USER_STATES.SELECTING_DEPARTURE) {
        state.userState = USER_STATES.SELECTED_DEPARTURE;
      }
    },
    
    setDestinationStation: (state, action) => {
      state.destinationStation = action.payload;
      if (state.userState === USER_STATES.SELECTING_ARRIVAL) {
        state.userState = USER_STATES.SELECTED_ARRIVAL;
        state.showTripInfoSheet = true;
      }
    },
    
    setDepartureTime: (state, action) => {
      const { selectedTime, bookingType } = action.payload;
      state.departureTime = selectedTime;
      state.bookingType = bookingType;
      state.isDepartTimeModalOpen = false;
      state.userState = USER_STATES.SELECTING_ARRIVAL;
    },
    
    toggleDepartTimeModal: (state) => {
      state.isDepartTimeModalOpen = !state.isDepartTimeModalOpen;
    },
    
    toggleRouteDetailsModal: (state) => {
      state.isRouteDetailsModalOpen = !state.isRouteDetailsModalOpen;
    },

    toggleTripInfoSheet: (state) => {
      state.showTripInfoSheet = !state.showTripInfoSheet;
    },
    
    clearDeparture: (state) => {
      state.departureStation = null;
      state.destinationStation = null;
      state.departureTime = null;
      state.bookingType = null;
      state.fareInfo = null;
      state.userState = USER_STATES.SELECTING_DEPARTURE;
      state.isDepartTimeModalOpen = false;
      state.isRouteDetailsModalOpen = false;
      state.showTripInfoSheet = false;
    },
    
    clearDestination: (state) => {
      state.destinationStation = null;
      state.fareInfo = null;
      state.userState = USER_STATES.SELECTING_ARRIVAL;
      state.isRouteDetailsModalOpen = false;
      state.showTripInfoSheet = false;
    },
    
    setError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    
    resetUserState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(processRouteInformation.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(processRouteInformation.fulfilled, (state, action) => {
        state.fareInfo = action.payload;
        state.isLoading = false;
      })
      .addCase(processRouteInformation.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      });
  },
});

export const {
  setDepartureStation,
  setDestinationStation,
  setDepartureTime,
  toggleDepartTimeModal,
  toggleRouteDetailsModal,
  toggleTripInfoSheet,
  clearDeparture,
  clearDestination,
  setError,
  resetUserState
} = userSlice.actions;

export const selectUserState = state => state.user.userState;
export const selectStations = state => ({
  departure: state.user.departureStation,
  destination: state.user.destinationStation
});
export const selectFareInfo = state => state.user.fareInfo;
export const selectIsLoading = state => state.user.isLoading;
export const selectError = state => state.user.error;

export default userSlice.reducer;
