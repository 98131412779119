import React from 'react';
import { useSelector } from 'react-redux';
import { Circle } from '@react-google-maps/api';
import { OverlayView } from '@react-google-maps/api';
import './styles.css';

const UserCircles = () => {
  const {
    userLocation,
    showCircles,
    circleDistances
  } = useSelector(state => state.location);

  if (!userLocation || !showCircles) return null;

  const getLabelPosition = (center, radius) => ({
    lat: center.lat + radius * 0.0000095, // Slightly adjusted for better positioning
    lng: center.lng
  });

  return (
    <>
      {circleDistances.map((radius, index) => (
        <React.Fragment key={radius}>
          {/* Main circle */}
          <Circle
            center={userLocation}
            radius={radius}
            options={{
              strokeColor: '#3B82F6',
              strokeOpacity: 0.4,
              strokeWeight: 1.5,
              fillColor: '#3B82F6',
              fillOpacity: 0.05,
              clickable: false,
              zIndex: 1
            }}
          />
          
          {/* Subtle outer ring */}
          <Circle
            center={userLocation}
            radius={radius + 2}
            options={{
              strokeColor: '#3B82F6',
              strokeOpacity: 0.15,
              strokeWeight: 1,
              fillOpacity: 0,
              clickable: false,
              zIndex: 1
            }}
          />

          {/* Distance Label */}
          <OverlayView
            position={getLabelPosition(userLocation, radius)}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div className="circle-distance-label">
              {(radius / 1000).toFixed(1)}km
            </div>
          </OverlayView>
        </React.Fragment>
      ))}
    </>
  );
};

export default React.memo(UserCircles);
