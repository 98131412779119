import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaArrowLeft, FaLocationArrow } from 'react-icons/fa';
import { goBack, selectMapState, setView } from '../../features/map/mapSlice';
import { toggleCircles } from '../../features/location/locationSlice';
import { VIEWS } from '../../constants/states';
import './styles.css';

const ViewBar = () => {
  const dispatch = useDispatch();
  
  const {
    mapInstance,
    currentView,
    canGoBack,
    selectedDistrict,
    isTransitioning
  } = useSelector(selectMapState);

  const {
    userLocation,
    departureStation,
    departureDistrict
  } = useSelector(state => ({
    userLocation: state.location.userLocation,
    departureStation: state.user.departureStation,
    departureDistrict: state.user.departureDistrict
  }));

const handleBack = useCallback(() => {
    if (isTransitioning) return;
    
    // Reset zoom constraints before transition
    if (mapInstance) {
      mapInstance.setOptions({
        minZoom: 0,
        maxZoom: 22
      });
    }

    dispatch(goBack({ departureDistrict }));
  }, [dispatch, isTransitioning, mapInstance, departureDistrict]);

  const handleLocateMe = useCallback(() => {
    if (!mapInstance || !userLocation || isTransitioning) return;
    
    // Reset zoom constraints first
    mapInstance.setOptions({
      minZoom: 0,
      maxZoom: 22
    });

    // Create bounds around user location
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(userLocation);
    bounds.extend({
      lat: userLocation.lat + 0.01,
      lng: userLocation.lng + 0.01
    });
    
    dispatch(setView({
      ...VIEWS.ME_VIEW,
      name: VIEWS.ME_VIEW.name,
      center: userLocation,
      bounds,
      zoom: VIEWS.ME_VIEW.zoom,
      tilt: VIEWS.ME_VIEW.tilt,
      heading: 0
    }));
    dispatch(toggleCircles(true));
  }, [mapInstance, userLocation, dispatch, isTransitioning]);


  const getViewTitle = () => {
    switch (currentView?.name) {
      case VIEWS.DISTRICT_VIEW.name:
        return selectedDistrict?.name || departureDistrict?.name || 'District View';
      case VIEWS.AREA_VIEW.name:
        return selectedDistrict?.name || 'Area View';
      case VIEWS.STATION_VIEW.name:
        return departureStation?.address || 'Station View';
      case VIEWS.ME_VIEW.name:
        return 'Current Location';
      default:
        return 'Hong Kong Transit';
    }
  };

  const shouldHideButtons = currentView?.name === VIEWS.STATION_VIEW.name ||
                          currentView?.name === VIEWS.DRIVE_VIEW.name;

  return (
    <div className="view-bar">
      {canGoBack && !shouldHideButtons && (
        <button 
          onClick={handleBack}
          className="navigation-button"
          aria-label="Go back"
          disabled={isTransitioning}
        >
          <FaArrowLeft />
        </button>
      )}
      <div className="view-title">
        <h2>{getViewTitle()}</h2>
      </div>
      {!shouldHideButtons && (
        <button 
          onClick={handleLocateMe}
          disabled={!userLocation || isTransitioning}
          className="navigation-button"
          aria-label="Locate me"
        >
          <FaLocationArrow />
        </button>
      )}
    </div>
  );
};

export default React.memo(ViewBar);
