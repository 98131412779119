// src/utils/timeHelpers.js
import { PEAK_HOURS } from '../constants/config';

export const isPeakTime = (date = new Date()) => {
  const hour = date.getHours();
  return PEAK_HOURS.some(period => hour >= period.start && hour < period.end);
};

export const getNextDepartureTimes = (count = 3, minDelay = 45) => {
  const now = new Date();
  const times = [];
  
  for (let i = 0; i < count; i++) {
    const delayMinutes = minDelay + (i * 45) + (Math.random() * 15);
    const departureTime = new Date(now.getTime() + delayMinutes * 60000);
    times.push({
      value: departureTime.getTime(),
      label: departureTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      })
    });
  }
  
  return times;
};