const locationMiddleware = store => next => action => {
  if (action.type === 'location/setUserLocation') {
    navigator.geolocation?.getCurrentPosition(
      position => {
        next({
          ...action,
          payload: {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
        });
      },
      error => {
        console.error('Geolocation error:', error);
        next(action);
      }
    );
    return;
  }
  return next(action);
};

const mapsMiddleware = store => next => action => {
  if (action.type === 'map/setMapInstance') {
    const prevState = store.getState();
    if (prevState.map.mapInstance) {
      google.maps.event.clearInstanceListeners(prevState.map.mapInstance);
    }
  }
  return next(action);
};

export const middleware = [
  locationMiddleware,
  mapsMiddleware,
];
