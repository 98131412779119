// src/components/UserOverlay/index.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import { OverlayView } from '@react-google-maps/api';
import './styles.css';

const UserOverlay = () => {
  const {
    userLocation,
    mapHeading,
    isAnimating,
    currentView
  } = useSelector(state => ({
    userLocation: state.location.userLocation,
    mapHeading: state.map.heading,
    isAnimating: state.location.isAnimating,
    currentView: state.map.currentView
  }));

  if (!userLocation) return null;

  const isMeView = currentView?.name === 'ME_VIEW';

  return (
    <OverlayView
      position={userLocation}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div 
        className={`user-location-marker ${isAnimating ? 'animating' : ''} ${isMeView ? 'me-view' : ''}`}
        style={{
          transform: `translate(-50%, -50%) rotate(${mapHeading}deg)`
        }}
      >
        {/* Main marker dot */}
        <div className="marker-inner" />
        
        {/* Breathing effect circle */}
        <div className="marker-pulse" />
        
        {/* Additional pulse rings for ME_VIEW */}
        {isMeView && (
          <>
            <div className="marker-pulse-ring ring-1" />
            <div className="marker-pulse-ring ring-2" />
          </>
        )}
      </div>
    </OverlayView>
  );
};

export default React.memo(UserOverlay);
