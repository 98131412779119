// src/components/LoadingSpinner/index.jsx
import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const LoadingSpinner = ({ size = 'medium', label = 'Loading...' }) => (
  <div className="loading-container">
    <svg className={`spinner ${size}`} viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="4"
      />
    </svg>
    <span className="loading-text">{label}</span>
  </div>
);

LoadingSpinner.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.string
};

export default React.memo(LoadingSpinner);