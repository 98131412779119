// src/hooks/useMapTransitions.js
import { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  setView,
  setTransitionState,
  handleMapStateChange
} from '../features/map/mapSlice';
import { calculateBounds, getAdaptivePadding } from '../utils/mapHelpers';
import { getViewSettingsForState } from '../utils/viewUtils';
import { USER_STATES, VIEWS, ZOOM_THRESHOLDS } from '../constants/states';

export const useMapTransitions = (mapInstance) => {
  const dispatch = useDispatch();
  const transitionTimeoutRef = useRef(null);
  const pendingTransitionRef = useRef(null);
  
  const {
    userState,
    departureStation,
    destinationStation,
    selectedDistrict,
    currentView,
    isTransitioning,
    stations,
    userLocation
  } = useSelector(state => ({
    userState: state.user.userState,
    departureStation: state.user.departureStation,
    destinationStation: state.user.destinationStation,
    selectedDistrict: state.map.selectedDistrict,
    currentView: state.map.currentView,
    isTransitioning: state.map.isTransitioning,
    stations: state.location.stationLocations,
    userLocation: state.location.userLocation
  }));

  const clearPendingTransitions = useCallback(() => {
    if (transitionTimeoutRef.current) {
      clearTimeout(transitionTimeoutRef.current);
      transitionTimeoutRef.current = null;
    }
    if (pendingTransitionRef.current) {
      clearTimeout(pendingTransitionRef.current);
      pendingTransitionRef.current = null;
    }
  }, []);

  const handleViewTransition = useCallback(async (viewSettings, options = {}) => {
    if (!mapInstance || !viewSettings || (isTransitioning && !options.force)) return;

    clearPendingTransitions();

    try {
      // Remove existing zoom constraints
      mapInstance.setOptions({
        minZoom: undefined,
        maxZoom: undefined
      });

      await new Promise(resolve => setTimeout(resolve, 50));

      let adjustedSettings = { ...viewSettings };
      
      // Handle transitions based on view type
      if (viewSettings.name === VIEWS.AREA_VIEW.name && selectedDistrict) {
        const districtStations = stations.filter(
          station => station.district === selectedDistrict.name
        );
        
        if (districtStations.length > 0) {
          const bounds = calculateBounds(selectedDistrict.position, districtStations);
          if (bounds) {
            const padding = getAdaptivePadding(bounds);
            adjustedSettings = {
              ...adjustedSettings,
              bounds,
              padding,
              zoom: ZOOM_THRESHOLDS.AREA_VIEW.default,
              tilt: VIEWS.AREA_VIEW.tilt,
              heading: 0,
              center: selectedDistrict.position
            };
          }
        }
      } else if (viewSettings.name === VIEWS.DISTRICT_VIEW.name && selectedDistrict) {
        adjustedSettings = {
          ...adjustedSettings,
          center: selectedDistrict.position,
          zoom: ZOOM_THRESHOLDS.DISTRICT_VIEW.default,
          tilt: VIEWS.DISTRICT_VIEW.tilt,
          heading: 0
        };
      }

      dispatch(setTransitionState(true));
      dispatch(setView(adjustedSettings));
      
      await handleMapStateChange(mapInstance, adjustedSettings);
      
      // Schedule transition state reset
      transitionTimeoutRef.current = setTimeout(() => {
        dispatch(setTransitionState(false));
        
        // Handle automatic transition to AREA_VIEW from DISTRICT_VIEW if needed
        if (options.autoTransitionToArea && 
            adjustedSettings.name === VIEWS.DISTRICT_VIEW.name) {
          pendingTransitionRef.current = setTimeout(() => {
            const areaViewSettings = getViewSettingsForState('AREA_VIEW', selectedDistrict.position);
            handleViewTransition(areaViewSettings, { force: true });
          }, 100);
        }
      }, 500);
      
    } catch (error) {
      console.error('Transition error:', error);
      clearPendingTransitions();
      dispatch(setTransitionState(false));
    }
  }, [dispatch, mapInstance, isTransitioning, selectedDistrict, stations, clearPendingTransitions]);

  useEffect(() => {
    if (!mapInstance || isTransitioning) return;

    let viewSettings;
    let options = {};

    switch (userState) {
      case USER_STATES.SELECTING_DEPARTURE:
      case USER_STATES.SELECTING_ARRIVAL:
        if (selectedDistrict) {
          if (currentView?.name === VIEWS.CITY_VIEW.name) {
            // From CITY_VIEW, go to DISTRICT_VIEW first
            viewSettings = {
              ...getViewSettingsForState('DISTRICT_VIEW', selectedDistrict.position),
              zoom: ZOOM_THRESHOLDS.DISTRICT_VIEW.default
            };
            options.autoTransitionToArea = true;
          } else if (currentView?.name === VIEWS.AREA_VIEW.name) {
            viewSettings = {
              ...getViewSettingsForState('DISTRICT_VIEW', selectedDistrict.position),
              zoom: ZOOM_THRESHOLDS.DISTRICT_VIEW.default
            };
          }
        } else {
          viewSettings = {
            ...getViewSettingsForState('CITY_VIEW')
          };
        }
        break;

      case USER_STATES.SELECTED_DEPARTURE:
        if (departureStation) {
          viewSettings = {
            ...getViewSettingsForState('STATION_VIEW', departureStation.position),
            zoom: ZOOM_THRESHOLDS.STATION_VIEW.default
          };
        }
        break;

      case USER_STATES.SELECTED_ARRIVAL:
        if (departureStation && destinationStation) {
          const bounds = calculateBounds(null, [departureStation, destinationStation]);
          viewSettings = {
            ...getViewSettingsForState('DRIVE_VIEW'),
            bounds,
            zoom: ZOOM_THRESHOLDS.DRIVE_VIEW.default
          };
        }
        break;

      default:
        break;
    }

    if (viewSettings && currentView?.name !== viewSettings.name) {
      handleViewTransition(viewSettings, options);
    }
  }, [
    mapInstance,
    userState,
    departureStation,
    destinationStation,
    selectedDistrict,
    currentView,
    handleViewTransition,
    isTransitioning
  ]);

  // Cleanup
  useEffect(() => {
    return () => {
      clearPendingTransitions();
    };
  }, [clearPendingTransitions]);

  return {
    handleViewTransition
  };
};

export default useMapTransitions;
