// src/components/StationMarkers/index.jsx
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayView } from '@react-google-maps/api';
import { setView } from '../../features/map/mapSlice';
import { setDepartureStation, setDestinationStation } from '../../features/user/userSlice';
import { getViewSettingsForState } from '../../utils/viewUtils';
import { USER_STATES, VIEWS, ZOOM_THRESHOLDS } from '../../constants/states';
import StationMarkerSVG from './StationMarkerSVG';
import './styles.css';

const StationMarkers = () => {
  const dispatch = useDispatch();
  
  const {
    stations,
    userState,
    departureStation,
    destinationStation,
    selectedDistrict,
    mapInstance,
    currentView,
    isTransitioning
  } = useSelector(state => ({
    stations: state.location.stationLocations,
    userState: state.user.userState,
    departureStation: state.user.departureStation,
    destinationStation: state.user.destinationStation,
    selectedDistrict: state.map.selectedDistrict,
    mapInstance: state.map.mapInstance,
    currentView: state.map.currentView,
    isTransitioning: state.map.isTransitioning
  }));

  const handleStationClick = useCallback((station) => {
    if (!mapInstance || isTransitioning) return;

    const stationView = {
      ...getViewSettingsForState('STATION_VIEW', station.position),
      zoom: ZOOM_THRESHOLDS.STATION_VIEW.default
    };
    
    dispatch(setView(stationView));

    if (userState === USER_STATES.SELECTING_DEPARTURE ||
        userState === USER_STATES.SELECTED_DEPARTURE) {
      dispatch(setDepartureStation(station));
    } else if (userState === USER_STATES.SELECTING_ARRIVAL) {
      dispatch(setDestinationStation(station));
    }
  }, [dispatch, mapInstance, userState, isTransitioning]);

  const visibleStations = useMemo(() => {
    if (!stations?.length) return [];
    
    // Show stations in AREA_VIEW for selected district
    if (currentView?.name === VIEWS.AREA_VIEW.name && selectedDistrict) {
      return stations.filter(station => 
        station.district === selectedDistrict.name
      );
    }

    // Show stations in DISTRICT_VIEW for selected district
    if (currentView?.name === VIEWS.DISTRICT_VIEW.name && selectedDistrict) {
      return stations.filter(station => 
        station.district === selectedDistrict.name
      );
    }
    
    // In STATION_VIEW, only show the selected station
    if (currentView?.name === VIEWS.STATION_VIEW.name) {
      const selectedStation = departureStation || destinationStation;
      return selectedStation ? [selectedStation] : [];
    }
    
    // Hide stations in other views
    return [];
  }, [stations, selectedDistrict, currentView, departureStation, destinationStation]);

  const getMarkerType = useCallback((station) => {
    if (station.id === departureStation?.id) return 'departure';
    if (station.id === destinationStation?.id) return 'destination';
    return 'default';
  }, [departureStation, destinationStation]);

  if (!visibleStations.length) return null;

  return (
    <>
      {visibleStations.map((station) => (
        <OverlayView
          key={station.id}
          position={station.position}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div 
            className="station-marker"
            onClick={() => handleStationClick(station)}
            role="button"
            tabIndex={0}
            aria-label={`Station: ${station.place}`}
            data-selected={station.id === departureStation?.id || station.id === destinationStation?.id}
          >
            <StationMarkerSVG
              name={station.place}
              type={getMarkerType(station)}
              isSelected={station.id === departureStation?.id || station.id === destinationStation?.id}
            />
          </div>
        </OverlayView>
      ))}
    </>
  );
};

export default React.memo(StationMarkers);
